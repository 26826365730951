<template>
<v-card
  class="mx-auto mt-5"
  outlined
  :disabled='isDone'
>
  <v-row>
    <v-col cols="10">
      <v-list-item
        three-line
      >
        <v-list-item-avatar
          tile
          size="30"
        >
          <img src="../assets/logo.png" alt="">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline"> шаг {{ step_data.id }}</v-list-item-title>
          <v-list-item-subtitle>{{ getFileName }}</v-list-item-subtitle>
          <v-btn
            v-if="isTeacher || (isStudent && !isDone)"
            class="auth-button"
            color="primary"
            type="submit"
            outlined
            height="30"
            :href=linkToFile
            target="_blank"
          >Скачать задание</v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="2" align-self="top" v-if="isTeacher">
      <v-icon
          id="delete-icon"
          color="black"
          link
          @click="deleteStep"
        >mdi-close</v-icon>
    </v-col>
    <v-col cols="2" align-self="center" v-if="isStudent && isDone">
      <span>Проверено </span>
      <v-icon
          id="header-icon"
          class="mr-3 green-icon"
        >mdi-emoticon</v-icon>
    </v-col>
  </v-row>
  <div
    v-if="isStudent && isActive"
  >
  <v-list-item>
    <v-file-input
      placeholder="Выберите файл для загрузки"
      prepend-icon="mdi-paperclip"
      show-size
      dense
      chips
      hide-details
      v-model="file"
    >
    </v-file-input>
  </v-list-item>
  <v-list-item link @click="addAnswer">
    <v-list-item-icon><v-icon>mdi-step-forward</v-icon></v-list-item-icon>
    <v-list-item-content>Добавить ответ для шага</v-list-item-content>
  </v-list-item>
  </div>
</v-card>
</template>

<script>
import {
  DELETE_STEP,
  GET_STEPS,
  ADD_ANSWER_TO_STEP,
  GET_ANSWERS,
} from '@/store/actions.type';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'StepCard',
  props: {
    step_data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    file: null,
  }),
  methods: {
    getIdToGetSteps() {
      const courseIdFormData = new FormData();
      courseIdFormData.append('id', this.course.id);
      return courseIdFormData;
    },
    getIdToGetAnswers() {
      const courseIdFormData = new FormData();
      courseIdFormData.append('courses_id', this.courses.map((item) => item.id));
      return courseIdFormData;
    },
    deleteStep() {
      this.$store.dispatch(DELETE_STEP, this.delete_step_serializer)
        .then(() => {
          this.$store.dispatch(GET_STEPS, this.getIdToGetSteps());
        });
    },
    addAnswer() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('step', this.step_data.id);
      this.file = null;
      this.$store.dispatch(ADD_ANSWER_TO_STEP, formData)
        .then(() => {
          this.$store.dispatch(GET_ANSWERS, this.getIdToGetAnswers());
        });
    },
  },
  computed: {
    answers() {
      return this.$store.getters.getAnswersByCourseId(parseInt(this.$route.params.id, 10)).data;
    },
    ...mapGetters({
      isTeacher: 'isTeacher',
      isStudent: 'isStudent',
    }),
    ...mapState({
      courses: (state) => state.courses.courses,
    }),
    delete_step_serializer() {
      return {
        id: this.step_data.id,
      };
    },
    course() {
      return this.$store.getters.getCourseById(parseInt(this.$route.params.id, 10));
    },
    linkToFile() {
      return `${process.env.VUE_APP_BACKEND_DOMAIN}media/${this.step_data.file}`;
    },
    getFileName() {
      const regexp = /\/*([a-zA-Zа-яА-ЯёЁ_\-\s%0-9]+\/)+/;
      return this.step_data.file.replace(regexp, '');
    },
    isActive() {
      const answersSteps = this.answers.map((item) => item.step);
      if (answersSteps) {
        if (answersSteps.includes(this.step_data.file)) {
          return false;
        }
      }
      return true;
    },
    isDone() {
      if (this.isTeacher) return false;
      const answer = this.answers.find((word) => word.step.indexOf(this.step_data.file) !== -1);
      if (answer) {
        return answer.is_done;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.green-icon::before {
  color:limegreen;
}
#delete-icon {
  font-size: 30px;
  float: right;
  margin-right: 25px;
  margin-top: 15px;
}
.headline {
  margin-bottom: 5px;
}
.auth-button {
  margin-top: 10px;
  font-size: 12px;
}
</style>
