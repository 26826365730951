const SESSION_KEY = 'savedcourses';

export const saveCourses = (data) => {
  window.localStorage.setItem(SESSION_KEY, JSON.stringify(data));
};

export const getCourses = () => {
  const session = window.localStorage.getItem(SESSION_KEY);
  if (session) {
    return JSON.parse(session);
  }
  return null;
};

export const destroyCourses = () => {
  window.localStorage.removeItem(SESSION_KEY);
};

export default {
  saveCourses, getCourses, destroyCourses,
};
