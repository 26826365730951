const SESSION_KEY = 'savedstudentCourses';

export const saveStudentCourses = (data) => {
  window.localStorage.setItem(SESSION_KEY, JSON.stringify(data));
};

export const getStudentCourses = () => {
  const session = window.localStorage.getItem(SESSION_KEY);
  if (session) {
    return JSON.parse(session);
  }
  return null;
};

export const destroyStudentCourses = () => {
  window.localStorage.removeItem(SESSION_KEY);
};

export default {
  saveStudentCourses, getStudentCourses, destroyStudentCourses,
};
