<template>
  <v-container>
    <v-row class="">
      <v-col cols="10" offset-md="1">
        <h2>Профиль</h2>
        <v-card outlined>
          <div class="main-info-wrap">
            <div id="avatar-wrap" class="pt-5">
              <v-list-item-avatar
                class="profile-avatar"
                color="success"
                height="150px"
                width="150px"
              >
                {{ avatarText() }}
              </v-list-item-avatar>
            </div>
            <div class="info-wrap">
              <div class="profile-line">
                Юзернейм: {{account.user.username}}
              </div>
              <div class="profile-line">
                Имя и фамилия: {{ fullName() }}
              </div>
              <div class="profile-line">
                Должность: {{account.person}}
              </div>
            </div>
          </div>
          <v-divider class="mt-3"></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex';

export default {
  name: 'Profile',
  computed: mapState({
    account: (state) => state.auth.account,
  }),
  methods: {
    avatarText() {
      let text = '';
      const { username, first_name, last_name } = this.account.user;
      if (first_name) {
        text = first_name.charAt(0);
        if (last_name) {
          text += last_name.charAt(0);
        }
      } else {
        text = username.charAt(0);
      }
      return text.toUpperCase();
    },
    fullName() {
      // eslint-disable-next-line no-unused-vars
      let name;
      const { email, first_name, last_name } = this.account.user;
      if (first_name) {
        name = first_name;
        if (last_name) {
          name += ` ${last_name}`;
        }
      } else {
        name = email;
      }
      return name;
    },
  },
};
</script>

<style scoped>
  #avatar-wrap {
    display: flex;
    margin-left: 25px;
    align-items: center;
    display: inline-block;
  }
  .main-info-wrap {
    display: grid;
    grid-template-columns: 1fr 5fr;
  }
  .profile-avatar {
    font-size: 42px;
    color: white;
  }
  .info-wrap {
    display: inline-block;
    margin-top: 35px;
  }
  .profile-line {
    margin-top: 15px;
  }
  h2 {
    margin-bottom: 15px;
  }
</style>
