<template>
  <v-container>
    <v-card outlined>
      <div class="main-info-wrap">
        <div id="avatar-wrap" class="pt-5">
        <v-list-item-avatar
          tile
          size="150"
        >
          <img src="@/assets/logo.png" alt="">
        </v-list-item-avatar>
        </div>
        <div class="info-wrap">
          <div class="profile-line">
            Название: {{student_course_data.course_name}}
          </div>
          <div class="profile-line">
            Описание: {{ student_course_data.course_description }}
          </div>
        </div>
        <div class="info-wrap">
          <div class="date-line">
            Создан: {{getDate}}
          </div>
        </div>
      </div>
      <v-divider class="mt-3"></v-divider>
      <div v-if="answers.length">
        <StudentAnswerCard
          :answer_data="answer"
          :key="answer.id"
          v-for="answer of answers"
        />
      </div>
      <p v-else class='no-items'>На этот курс ученик не выложил ответы.</p>
    </v-card>
  </v-container>
</template>

<script>
import StudentAnswerCard from '@/components/teacher/StudentAnswerCard.vue';

export default {
  name: 'StudentCourseCard',
  components: {
    StudentAnswerCard,
  },
  props: {
    student_course_data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  data: () => ({
  }),
  methods: {
  },
  computed: {
    student() {
      return this.$store.getters.getStudentById(parseInt(this.$route.params.id, 10));
    },
    answers() {
      return this.$store.getters.getAnswersByCourseId(this.student_course_data.id).data;
    },
    getDate() {
      const date = new Date(this.student_course_data.date);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
  },
};
</script>

<style scoped>
  #avatar-wrap {
    display: flex;
    margin-left: 25px;
    align-items: center;
    display: inline-block;
  }
  .main-info-wrap {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
  }
  .info-wrap {
    display: inline-block;
    margin-top: 15px;
  }
  .profile-line {
    margin-top: 5px;
    margin-left: 30px;
    font-size: 18px;
  }
  .date-line {
    margin-left: 30px;
    font-size: 14px;
  }
  .no-items {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
  }
</style>
