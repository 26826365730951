const SESSION_KEY = 'savedstudents';

export const saveStudents = (data) => {
  window.localStorage.setItem(SESSION_KEY, JSON.stringify(data));
};

export const getStudents = () => {
  const session = window.localStorage.getItem(SESSION_KEY);
  if (session) {
    return JSON.parse(session);
  }
  return null;
};

export const destroyStudents = () => {
  window.localStorage.removeItem(SESSION_KEY);
};

export default {
  saveStudents, getStudents, destroyStudents,
};
