<template>
<v-card
  class="mx-auto"
  outlined
>
  <v-row>
    <v-col cols="7">
      <v-list-item
        three-line
      >
        <v-list-item-avatar
          tile
          size="30"
        >
          <img src="@/assets/logo.png" alt="">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">ответ на шаг {{ answer_data.id }}</v-list-item-title>
          <v-list-item-subtitle>{{ getFileName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="2" align-self="center">
          <v-btn
            v-if="!answer_data.is_done"
            class="done-button"
            color="success"
            type="submit"
            outlined
            height="30"
            @click="doneAnswer"
          >проверено</v-btn>
    </v-col>
    <v-col cols="2" align-self="center">
          <v-btn
            class="download-button"
            color="primary"
            type="submit"
            outlined
            height="30"
            :href=linkToFile
            target="_blank"
          >скачать овтет</v-btn>
    </v-col>
    <v-col cols="1" align-self="center" v-if="answer_data.is_done">
      <v-icon
        id="header-icon"
        class="mr-3 green-icon"
        color="black"
      >mdi-clipboard-check</v-icon>
    </v-col>
    <v-col cols="1" align-self="center" v-if="!answer_data.is_done">
      <v-icon
        id="header-icon"
        class="mr-3 red-icon"
        color="black"
      >mdi-close-box</v-icon>
    </v-col>
  </v-row>
</v-card>
</template>

<script>
import { MAKE_ANSWER_DONE, GET_ANSWERS } from '@/store/actions.type';
import { mapState } from 'vuex';

export default {
  name: 'StudentAnswerCard',
  props: {
    answer_data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    file: null,
  }),
  methods: {
    doneAnswer() {
      this.$store.dispatch(MAKE_ANSWER_DONE, this.getIdToDoneAnswer())
        .then(() => {
          this.$store.dispatch(GET_ANSWERS, this.getIdToGetAnswers());
        });
    },
    getIdToGetAnswers() {
      const courseIdFormData = new FormData();
      courseIdFormData.append('courses_id', this.student_courses.map((item) => item.id));
      courseIdFormData.append('student_id', this.student.id);
      return courseIdFormData;
    },
    getIdToDoneAnswer() {
      const answerIdFormData = new FormData();
      answerIdFormData.append('id', this.answer_data.id);
      return answerIdFormData;
    },
  },
  computed: {
    ...mapState({
      student_courses: (state) => state.courses.student_courses,
    }),
    getFileName() {
      const regexp = /\/*([a-zA-Zа-яА-ЯёЁ_\-\s%0-9]+\/)+/;
      return this.answer_data.file.replace(regexp, '');
    },
    linkToFile() {
      return `${process.env.VUE_APP_BACKEND_DOMAIN}${this.answer_data.file.substr(1)}`;
    },
    student() {
      return this.$store.getters.getStudentById(parseInt(this.$route.params.id, 10));
    },
  },
};
</script>

<style scoped>
.green-icon::before {
  color:limegreen;
}
.red-icon::before {
  color:red;
}
.done-button {
  font-size: 12px;
}
.download-button {
  font-size: 10px;
}
</style>
