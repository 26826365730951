import store from '@/store';

export const isAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    return next();
  }
  return next({ name: 'Auth' });
};

export const isNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    return next();
  }
  return next({ name: 'Home' });
};

export const isTeacher = (to, from, next) => {
  if (store.getters.getPerson === 'teacher') {
    return next();
  }
  return next({ name: '404' });
};

export const isStudent = (to, from, next) => {
  if (store.getters.getPerson === 'student') {
    return next();
  }
  return next({ name: '404' });
};

export default {
  isAuthenticated,
  isNotAuthenticated,
  isTeacher,
  isStudent,
};
