<template>
  <v-container>
    <v-row class="">
      <v-col cols="10" offset-md="1">
        <h2>Список всех курсов, которые есть на платформе</h2>
        <v-text-field
          class="text-field"
          hide-details
          type="text"
          v-model="search"
          placeholder="Введите название курса"
          label="Поиск по названию"
          outlined
        ></v-text-field>
        <div v-if="all_courses.length">
          <CourseCard
            :course_data="course"
            :key="course.id"
            v-for="course of courses_filter"
          />
        </div>
        <p v-else class='no-items'>На платформу не загруженно ни одного курса!</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CourseCard from '@/components/CourseCard.vue';
import { mapState } from 'vuex';
import { GET_ALL_COURSES, GET_COURSES } from '@/store/actions.type';

export default {
  name: 'AlCourses',
  components: {
    CourseCard,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapState({
      all_courses: (state) => state.courses.all_courses,
      courses: (state) => state.courses.courses,
    }),
    courses_filter() {
      // eslint-disable-next-line max-len
      return this.all_courses.filter((item) => item.course_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
    },
  },
  mounted() {
    this.$store.dispatch(GET_ALL_COURSES);
    this.$store.dispatch(GET_COURSES);
  },
};
</script>

<style scoped>
  h2 {
    margin-bottom: 25px;
  }
  .text-field {
    margin-bottom: 25px;
  }
  .no-items {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
  }
</style>
