<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      elevate-on-scroll
    >
      <v-app-bar-nav-icon
        color="white"
        class="mr-5"
        @click.stop="leftMenu = !leftMenu"
        v-if="isAuthenticated"
      ></v-app-bar-nav-icon>
      <div id="header-text-wrap">
        <v-icon
          id="header-icon"
          class="mr-3"
          color="white"
        >mdi-shape-plus</v-icon>
        <span id="header-text">DO Platform</span>
      </div>
      <span class="username-header" v-if="account">
        Вы вошли как  {{account.user.username}}
      </span>
      <div id="logout-btn-wrap" v-if="isAuthenticated">
        <v-btn
          text
          color="white"
          @click.prevent="logout"
        >
          <v-icon class="mr-1">mdi-exit-to-app</v-icon>
          <span>Выйти</span>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isAuthenticated"
      v-model="leftMenu"
      app
    >
      <left-menu/>
    </v-navigation-drawer>
    <v-main>
      <loading
        :loading="loadingActive"
        color="primary"
      />
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { LOGOUT } from '@/store/actions.type';
import loading from '@/components/Loading.vue';
import leftMenu from '@/components/LeftMenu.vue';

export default {
  name: 'App',

  components: {
    loading,
    leftMenu,
  },
  computed: {
    ...mapState({
      loadingActive: (state) => state.loading.loadingActive,
      isAuthenticated: (state) => state.auth.isAuthenticated,
      account: (state) => state.auth.account,
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'Auth' }))
        .catch(() => this.$router.push({ name: 'Auth' }));
    },
  },
  data: () => ({
    leftMenu: true,
  }),
};
</script>

<style scoped>
body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
  font-family: "Roboto", sans-serif !important;
}

.v-application a {
  text-decoration: none;
  font-weight: 500;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity .1s ease;
}
.fade-leave-active {
  transition: opacity .1s ease;
  opacity: 0;
}
#logout-btn-wrap{
  margin-left: auto;
}
.username-header{
  margin-left: auto;
  color: white;
}
.header {
  max-height: 48px;
}
#header-text {
  color: white;
  /* font-weight: 48px; */
}
.loader-spacer {
  height: 4px;
}
</style>
