import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  isAuthenticated,
  isNotAuthenticated,
  isTeacher,
  isStudent,
} from '@/router/guards';
import Home from '../views/Home.vue';
import Auth from '../views/Auth.vue';
import Register from '../views/Register.vue';
import NotFound from '../views/NotFound.vue';
import Profile from '../views/Profile.vue';
import AllCourses from '../views/AllCourses.vue';
import TeacherMyCourses from '../views/teacher/TeacherMyCourses.vue';
import TeacherCourse from '../views/teacher/TeacherCourse.vue';
import TeacherMyStudents from '../views/teacher/TeacherMyStudents.vue';
import StudentPage from '../views/teacher/StudentPage.vue';
import StudentMyCourses from '../views/student/StudentMyCourses.vue';
import StudentCourse from '../views/student/StudentCourse.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isAuthenticated,
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    beforeEnter: isNotAuthenticated,
  },
  {
    path: '/register-as-teacher',
    name: 'RegisterAsTeacher',
    component: Register,
    beforeEnter: isNotAuthenticated,
  },
  {
    path: '/register-as-student',
    name: 'RegisterAsStudent',
    component: Register,
    beforeEnter: isNotAuthenticated,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: isAuthenticated,
  },
  {
    path: '/all-courses',
    name: 'AllCoursesPage',
    component: AllCourses,
    beforeEnter: isAuthenticated,
  },
  {
    path: '/t-my-courses',
    name: 'TeacherMyCourses',
    component: TeacherMyCourses,
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next);
      isTeacher(to, from, next);
    },
  },
  {
    path: '/t-my-courses/:id',
    name: 'TeacherCoursePage',
    component: TeacherCourse,
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next);
      isTeacher(to, from, next);
    },
  },
  {
    path: '/t-my-students',
    name: 'TeacherMyStudents',
    component: TeacherMyStudents,
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next);
      isTeacher(to, from, next);
    },
  },
  {
    path: '/t-my-students/:id',
    name: 'StudentPage',
    component: StudentPage,
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next);
      isTeacher(to, from, next);
    },
  },
  {
    path: '/s-my-courses',
    name: 'StudentMyCourses',
    component: StudentMyCourses,
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next);
      isStudent(to, from, next);
    },
  },
  {
    path: '/s-my-courses/:id',
    name: 'StudentCoursePage',
    component: StudentCourse,
    beforeEnter: (to, from, next) => {
      isAuthenticated(to, from, next);
      isStudent(to, from, next);
    },
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
