export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER_AS_TEACHER = 'registerAsTeacher';
export const REGISTER_AS_STUDENT = 'registerAsStudent';
export const GET_ACCOUNT = 'getAccountInfo';
export const CREATE_COURSE = 'createCourse';
export const GET_COURSES = 'getCourses';
export const DELETE_COURSE = 'deleteCourses';
export const ADD_STEP_TO_COURSE = 'addStepToCourse';
export const GET_STEPS = 'getSteps';
export const DELETE_STEP = 'deleteStep';
export const GET_ALL_COURSES = 'getAllCourses';
export const SUBSCRIBE_COURSE = 'subscribeCourses';
export const UNSUBSCRIBE_COURSE = 'unsubscribeCourses';
export const ADD_ANSWER_TO_STEP = 'addAnswerToStep';
export const GET_STUDENTS = 'getStudents';
export const GET_COURSES_BY_STUDENT = 'getCoursesByStudent';
export const MAKE_ANSWER_DONE = 'makeAnswerDone';
export const GET_ANSWERS = 'getAnswers';
