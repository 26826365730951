<template>
  <v-container>
    <v-row>
      <v-col
        cols="7"
        xl="6"
        offset="1"
        >
        <h2>Список моих курсов</h2>
        <div v-if="courses.length">
          <CourseCard
            :course_data="course"
            :key="course.id"
            v-for="course of courses"
          />
        </div>
        <p v-else class='no-items'>Вы еще не создали ни одного курса!</p>
      </v-col>
      <v-divider class="mt-3" vertical></v-divider>
      <v-col cols="3">
        <v-form @submit.prevent="createCourse" class="create-course-form">
          <v-text-field
            class="create-course-input"
            dense
            hide-details
            height='20px'
            label="Название курса"
            color="secondary"
            name="course_name"
            type="text"
            outlined
            v-model="create_course_form.course_name"
          ></v-text-field>
          <v-textarea
            class="create-course-input"
            no-resize
            height='100px'
            hide-details
            label="Описание курса"
            color="secondary"
            name="course_description"
            type="text"
            outlined
            v-model="create_course_form.course_description"
          ></v-textarea>
          <v-btn
            class="auth-button"
            color="primary"
            type="submit"
            depressed
            block
          >Создать курс</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CourseCard from '@/components/CourseCard.vue';
import { mapState } from 'vuex';
import { CREATE_COURSE, GET_COURSES } from '@/store/actions.type';

export default {
  name: 'TeacherMyCourses',
  components: {
    CourseCard,
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses.courses,
    }),
  },
  methods: {
    createCourse() {
      this.$store.dispatch(CREATE_COURSE, this.create_course_form)
        .then(() => {
          this.$store.dispatch(GET_COURSES);
        });
    },
  },
  mounted() {
    this.$store.dispatch(GET_COURSES);
  },
  data: () => ({
    create_course_form: {
      course_name: '',
      course_description: '',
    },
    delete_course_serializer: {
      course_name: '',
      id: '',
    },
  }),
};
</script>

<style scoped>
  #avatar-wrap {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .create-course-input {
    margin-bottom: 15px;
  }
  .create-course-form {
    position: sticky;
    top: 88px;
    z-index: 2;
  }
  h2 {
    margin-bottom: 15px;
  }
  .no-items {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
  }
</style>
