<template>
  <v-container>
    <v-row>
      <v-col
        cols="10"
        offset-md="1"
        >
        <h2>Мои курсы, на которые подписан {{getStudentFullName}}</h2>
        <div v-if="student_courses.length">
          <StudentCourseCard
            :student_course_data="student_course"
            :key="student_course.id"
            v-for="student_course of student_courses"
          />
        </div>
        <p v-else class='no-items'>cant be!</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable camelcase */
import {
  GET_COURSES_BY_STUDENT,
  GET_ANSWERS,
} from '@/store/actions.type';
import { mapState } from 'vuex';
import StudentCourseCard from '@/components/teacher/StudentCourseCard.vue';

export default {
  name: 'StudentPage',
  components: {
    StudentCourseCard,
  },
  data: () => ({
    file: null,
  }),
  mounted() {
    this.$store.dispatch(GET_COURSES_BY_STUDENT, this.getIdToGetCourses())
      .then(() => {
        this.$store.dispatch(GET_ANSWERS, this.getIdToGetAnswers());
      });
  },
  methods: {
    getIdToGetCourses() {
      const studentIdFormData = new FormData();
      studentIdFormData.append('id', this.student.id);
      return studentIdFormData;
    },
    getIdToGetAnswers() {
      const courseIdFormData = new FormData();
      courseIdFormData.append('courses_id', this.student_courses.map((item) => item.id));
      courseIdFormData.append('student_id', this.student.id);
      return courseIdFormData;
    },
  },
  computed: {
    ...mapState({
      student_courses: (state) => state.courses.student_courses,
    }),
    student() {
      return this.$store.getters.getStudentById(parseInt(this.$route.params.id, 10));
    },
    getStudentFullName() {
      return `${this.student.student_firstname} ${this.student.student_lastname}`;
    },
  },
};
</script>

<style scoped>
  #avatar-wrap {
    display: flex;
    margin-left: 25px;
    align-items: center;
    display: inline-block;
  }
  .main-info-wrap {
    display: grid;
    grid-template-columns: 1fr 5fr;
  }
  .info-wrap {
    display: inline-block;
    margin-top: 15px;
  }
  .profile-line {
    margin-top: 15px;
  }
  h2 {
    margin-bottom: 25px;
  }
  .no-items {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
  }
</style>
