<template>
<v-card
  class="mx-auto mb-5"
  outlined
  @click="pushToCoursePage"
  link
>
  <v-row>
    <v-col cols="9">
      <v-list-item
        three-line
      >
        <v-list-item-avatar
          tile
          size="150"
        >
          <img src="../assets/logo.png" alt="">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline">{{ course_data.course_name }}</v-list-item-title>
          <v-list-item-subtitle>{{ course_data.course_description }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="isAllCourses">{{ teacherFullName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="3">
      <v-icon
        v-if="!isAllCourses"
        id="delete-icon"
        color="black"
        link
        @click.stop="deleteCourse"
      >mdi-close</v-icon>
      <v-btn
        v-if="isStudent && isAllCourses"
        :disabled='isSubscribed'
        class="button"
        color="primary"
        type="submit"
        depressed
        @click.stop="subscribeCourse"
      >подписаться</v-btn>
    </v-col>
  </v-row>
</v-card>
</template>

<script>
import {
  DELETE_COURSE,
  GET_COURSES,
  SUBSCRIBE_COURSE,
  GET_ALL_COURSES,
  UNSUBSCRIBE_COURSE,
} from '@/store/actions.type';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CourseCard',
  props: {
    course_data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
  }),
  methods: {
    deleteCourse() {
      if (this.isTeacher) {
        this.$store.dispatch(DELETE_COURSE, this.course_serializer)
          .then(() => {
            this.$store.dispatch(GET_COURSES);
          });
      } else if (this.isStudent) {
        this.$store.dispatch(UNSUBSCRIBE_COURSE, this.course_serializer)
          .then(() => {
            this.$store.dispatch(GET_COURSES);
          });
      }
    },
    subscribeCourse() {
      this.$store.dispatch(SUBSCRIBE_COURSE, this.course_serializer)
        .then(() => {
          this.$store.dispatch(GET_ALL_COURSES);
        });
    },
    pushToCoursePage() {
      if (this.isTeacher && !this.isAllCourses) {
        this.$router.push({ name: 'TeacherCoursePage', params: { id: this.course_data.id } });
      } else if (this.isStudent && !this.isAllCourses) {
        this.$router.push({ name: 'StudentCoursePage', params: { id: this.course_data.id } });
      }
    },
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses.courses,
    }),
    ...mapGetters({
      isTeacher: 'isTeacher',
      isStudent: 'isStudent',
    }),
    getDate() {
      const date = new Date(this.course_data.date);
      return `${date.getDate()}:${date.getMonth() + 1}:${date.getFullYear()}`;
    },
    getTime() {
      const date = new Date(this.course_data.date);
      return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },
    course_serializer() {
      return {
        course_name: this.course_data.course_name,
        id: this.course_data.id,
        course_description: this.course_data.course_description,
        date: this.course_data.date,
      };
    },
    isAllCourses() {
      if (this.$router.currentRoute.path === '/all-courses') {
        return true;
      // eslint-disable-next-line no-else-return
      } else {
        return false;
      }
    },
    isSubscribed() {
      if (this.isTeacher) return false;
      const coursesId = this.courses.map((item) => item.id);
      if (coursesId) {
        if (coursesId.includes(this.course_data.id)) {
          return true;
        }
      }
      return false;
    },
    teacherFullName() {
      return `${this.course_data.teacher_firstname} ${this.course_data.teacher_lastname}`;
    },
  },
};
</script>

<style scoped>
#delete-icon {
  font-size: 30px;
  float: right;
  margin-right: 25px;
  margin-top: 15px;
}
.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
