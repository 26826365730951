<template>
<v-card
  class="mx-auto mt-5"
  outlined
  @click="pushToStudentPage"
>
  <v-row>
    <v-col cols="12">
      <v-list-item
        target="_blank"
      >
        <v-list-item-avatar
          tile
          size="30"
          class="image"
        >
          <img src="@/assets/logo.png" alt="">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="headline"> {{ fullName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </v-row>
</v-card>
</template>

<script>

export default {
  name: 'Student',
  props: {
    student_data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    file: null,
  }),
  methods: {
    pushToStudentPage() {
      this.$router.push({ name: 'StudentPage', params: { id: this.student_data.id } });
    },
  },
  computed: {
    fullName() {
      return `${this.student_data.student_firstname} ${this.student_data.student_lastname}`;
    },
  },
};
</script>

<style scoped>
.headline {
  margin-left: 15px;
}
.image {
  margin-left: 15px;
}
</style>
