/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import {
  LOGIN, LOGOUT, REGISTER_AS_STUDENT, REGISTER_AS_TEACHER,
} from '@/store/actions.type';
import Vue from 'vue';
import {
  PURGE_AUTH, SET_AUTH, SET_ERROR,
  START_LOADING, STOP_LOADING,
} from '@/store/mutations.type';
import {
  destroySession, getToken, saveSession, setHeader, getSession,
} from '@/common/session';
import {
  destroyCourses,
} from '@/common/course';
import {
  destroyAllCourses,
} from '@/common/all_courses';
import {
  destroyStudents,
} from '@/common/students';
import {
  destroyStudentCourses,
} from '@/common/student_courses';
import {
  destroyAnswers,
} from '@/common/answers_full';
import { unpackForRequest } from '@/common/register';

const state = {
  user: {},
  isAuthenticated: !!getToken(),
  account: getSession(),
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  user(state) {
    return state.user;
  },
  getPerson(state) {
    return state.account.person;
  },
  isTeacher(state) {
    return (state.account.person === 'teacher');
  },
  isStudent(state) {
    return (state.account.person === 'student');
  },
};

const actions = {
  [LOGIN](context, credentials) {
    context.commit(START_LOADING);
    return new Promise((resolve, reject) => {
      Vue.axios.post('users/login/', credentials)
        .then((response) => {
          context.commit(SET_AUTH, response.data);
          resolve(response);
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.response);
          reject(error.response.status);
          context.commit(STOP_LOADING);
        });
    });
  },
  [REGISTER_AS_TEACHER](context, data) {
    context.commit(START_LOADING);
    const request = unpackForRequest(data);
    return new Promise((resolve, reject) => {
      Vue.axios.post('users/register_as_teacher/', request)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [REGISTER_AS_STUDENT](context, data) {
    context.commit(START_LOADING);
    const request = unpackForRequest(data);
    return new Promise((resolve, reject) => {
      Vue.axios.post('users/register_as_student/', request)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('users/logout/')
        .then(() => {
          resolve();
          context.commit(PURGE_AUTH);
        })
        .catch((error) => {
          context.commit(PURGE_AUTH);
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.wrongCredentials = false;
    state.user = data;
    saveSession(data);
    state.account = data;
    setHeader();
  },
  [SET_ERROR](state, response) {
    if (response.status === 401) {
      state.isAuthenticated = false;
      state.user = {};
    }
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.wrongCredentials = false;
    state.user = {};
    destroySession();
    destroyCourses();
    destroyStudents();
    destroyStudentCourses();
    destroyAnswers();
    destroyAllCourses();
    state.account = getSession();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
