<template>
  <v-container>
    <v-row>
      <v-col
        cols="10"
        xl="6"
        offset="1"
        >
        <h2>Список курсов на которые я подписан</h2>
        <div v-if="courses.length">
          <CourseCard
            :course_data="course"
            :key="course.id"
            v-for="course of courses"
          />
        </div>
        <p v-else class='no-items'>Вы не подписаны ни на один курс.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CourseCard from '@/components/CourseCard.vue';
import { mapState } from 'vuex';
import { GET_COURSES, GET_ANSWERS } from '@/store/actions.type';

export default {
  name: 'StudentMyCourses',
  components: {
    CourseCard,
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses.courses,
    }),
  },
  methods: {
    getIdToGetAnswers() {
      const courseIdFormData = new FormData();
      courseIdFormData.append('courses_id', this.courses.map((item) => item.id));
      return courseIdFormData;
    },
  },
  mounted() {
    this.$store.dispatch(GET_COURSES);
    this.$store.dispatch(GET_ANSWERS, this.getIdToGetAnswers());
  },
  data: () => ({
    create_course_form: {
      course_name: '',
      course_description: '',
    },
    delete_course_serializer: {
      course_name: '',
      id: '',
    },
  }),
};
</script>

<style scoped>
  #avatar-wrap {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .create-course-input {
    margin-bottom: 15px;
  }
  .create-course-form {
    position: sticky;
    top: 88px;
    z-index: 2;
  }
  h2 {
    margin-bottom: 15px;
  }
  .no-items {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
  }
</style>
