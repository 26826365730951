<template>
  <v-container>
    <v-row>
      <v-col
        cols="10"
        offset-md="1"
        >
        <h2>Список всех учеников, которые подписаны на мои курсы</h2>
        <v-text-field
          hide-details
          type="text"
          v-model="search"
          placeholder="Введите имя или фамилию ученика"
          label="Поиск по имени или фамилии"
          outlined
        ></v-text-field>
        <div v-if="students.length">
          <Student
            :student_data="student"
            :key="student.id"
            v-for="student of students_filter"
          />
        </div>
        <p v-else class='no-items'>У вас нет ни одного ученика.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Student from '@/components/teacher/Student.vue';
import { mapState } from 'vuex';
import { GET_STUDENTS } from '@/store/actions.type';

export default {
  name: 'TeacherMyStudents',
  components: {
    Student,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapState({
      students: (state) => state.courses.students,
    }),
    students_filter() {
      return this.students.filter(
        (item) => item.student_firstname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        || item.student_lastname.indexOf(this.search) !== -1,
      );
    },
  },
  mounted() {
    this.$store.dispatch(GET_STUDENTS);
  },
};
</script>

<style scoped>
  #avatar-wrap {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  h2 {
    margin-bottom: 25px;
  }
  .no-items {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
  }
</style>
