<template>
  <div class="home">
    <div class="hello">
      Привет {{ getFullName }}!
    </div>
    <div class="desc">
      Сейчас ты находишься на начальной странице <br> Платформы для дистанционного обучения.
    </div>
    <div class="redirect">
      В меню слева ты найдешь всё что тебе необходимо!
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Home',
  computed: {
    ...mapState({
      account: (state) => state.auth.account,
    }),
    getFullName() {
      return `${this.account.user.first_name} ${this.account.user.last_name}`;
    },
  },
};
</script>

<style scoped>
.hello {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align:center;
  font-size: 42px;
}
.desc {
  text-align:center;
  font-size: 28px;
  margin-bottom: 30px;
}
.redirect {
  text-align:center;
  font-size: 28px;
}
</style>
