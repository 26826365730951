export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const START_LOADING = 'startLoading';
export const STOP_LOADING = 'stopLoading';
export const SET_COURSES = 'setCourses';
export const SET_STEPS = 'setSteps';
export const SET_ALL_COURSES = 'setAllCourses';
export const SET_STUDENTS = 'setStudents';
export const SET_STUDENT_COURSES = 'setStudentCourses';
export const SET_ANSWERS = 'setAnswers';
