const SESSION_KEY = 'savedAnswers';

export const saveAnswers = (data) => {
  window.localStorage.setItem(SESSION_KEY, JSON.stringify(data));
};

export const getAnswers = () => {
  const session = window.localStorage.getItem(SESSION_KEY);
  if (session) {
    return JSON.parse(session);
  }
  return null;
};

export const destroyAnswers = () => {
  window.localStorage.removeItem(SESSION_KEY);
};

export default {
  saveAnswers, getAnswers, destroyAnswers,
};
