/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from 'vue';
import {
  CREATE_COURSE,
  GET_COURSES,
  DELETE_COURSE,
  ADD_STEP_TO_COURSE,
  GET_STEPS,
  DELETE_STEP,
  GET_ALL_COURSES,
  SUBSCRIBE_COURSE,
  UNSUBSCRIBE_COURSE,
  ADD_ANSWER_TO_STEP,
  GET_STUDENTS,
  GET_COURSES_BY_STUDENT,
  MAKE_ANSWER_DONE,
  GET_ANSWERS,
} from '@/store/actions.type';
import {
  START_LOADING, STOP_LOADING,
  SET_COURSES,
  SET_STEPS,
  SET_ALL_COURSES,
  SET_STUDENTS,
  SET_STUDENT_COURSES,
  SET_ANSWERS,
} from '@/store/mutations.type';
import {
  setHeader,
} from '@/common/session';
import {
  saveCourses, getCourses,
} from '@/common/course';
import {
  saveAllCourses, getAllCourses,
} from '@/common/all_courses';
import {
  saveStudents, getStudents,
} from '@/common/students';
import {
  saveStudentCourses, getStudentCourses,
} from '@/common/student_courses';
import {
  saveAnswers, getAnswers,
} from '@/common/answers_full';

const state = {
  courses: getCourses(),
  steps: [],
  all_courses: getAllCourses(),
  students: getStudents(),
  student_courses: getStudentCourses(),
  answers_full: getAnswers(),
};

const getters = {
  getCourseById: (state) => (id) => state.courses.find((course) => course.id === id),
  getStudentById: (state) => (id) => state.students.find((student) => student.id === id),
  // eslint-disable-next-line max-len
  getAnswersByCourseId: (state) => (id) => state.answers_full.find((answers) => answers.course === id),
};

const teacherActions = {
  [GET_STUDENTS](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.get('courses/teacher_courses/get_students/', data)
        .then((response) => {
          context.commit(SET_STUDENTS, response.data);
          resolve(response);
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error.response.status);
          context.commit(STOP_LOADING);
        });
    });
  },
  [GET_COURSES_BY_STUDENT](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/teacher_courses/get_courses_by_student/', data)
        .then((response) => {
          context.commit(SET_STUDENT_COURSES, response.data);
          resolve(response);
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error.response.status);
          context.commit(STOP_LOADING);
        });
    });
  },
  [MAKE_ANSWER_DONE](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/teacher_courses/make_answer_done/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [CREATE_COURSE](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/teacher_courses/create_course/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [DELETE_COURSE](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/teacher_courses/delete_course/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [ADD_STEP_TO_COURSE](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/teacher_steps/create_step/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [DELETE_STEP](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/teacher_steps/delete_step/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
};

const studentActions = {
  [SUBSCRIBE_COURSE](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/student_courses/subscribe_course/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [UNSUBSCRIBE_COURSE](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/student_courses/unsubscribe_course/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
  [ADD_ANSWER_TO_STEP](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/student_steps/create_answer/', data)
        .then(() => {
          resolve();
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error);
          context.commit(STOP_LOADING);
        });
    });
  },
};

const overallActions = {
  [GET_COURSES](context, data) {
    context.commit(START_LOADING);
    setHeader();
    let person = '';
    if (context.rootGetters.isTeacher) {
      person = 'teacher';
    } else if (context.rootGetters.isStudent) {
      person = 'student';
    }
    return new Promise((resolve, reject) => {
      Vue.axios.get(`courses/${person}_courses/get_courses_as_${person}/`, data)
        .then((response) => {
          context.commit(SET_COURSES, response.data);
          resolve(response);
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error.response.status);
          context.commit(STOP_LOADING);
        });
    });
  },
  [GET_ANSWERS](context, data) {
    context.commit(START_LOADING);
    setHeader();
    let person = '';
    if (context.rootGetters.isTeacher) {
      person = 'teacher';
    } else if (context.rootGetters.isStudent) {
      person = 'student';
    }
    return new Promise((resolve, reject) => {
      Vue.axios.post(`courses/${person}_steps/get_answers/`, data)
        .then((response) => {
          context.commit(SET_ANSWERS, response.data);
          resolve(response);
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error.response.status);
          context.commit(STOP_LOADING);
        });
    });
  },
  [GET_ALL_COURSES](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.get('courses/overall_courses/get_all_courses/', data)
        .then((response) => {
          context.commit(SET_ALL_COURSES, response.data);
          resolve(response);
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error.response.status);
          context.commit(STOP_LOADING);
        });
    });
  },
  [GET_STEPS](context, data) {
    context.commit(START_LOADING);
    setHeader();
    return new Promise((resolve, reject) => {
      Vue.axios.post('courses/overall_courses/get_steps/', data)
        .then((response) => {
          context.commit(SET_STEPS, response.data);
          resolve(response);
          context.commit(STOP_LOADING);
        })
        .catch((error) => {
          reject(error.response.status);
          context.commit(STOP_LOADING);
        });
    });
  },
};

const mutations = {
  [SET_ALL_COURSES](state, data) {
    saveAllCourses(data);
    state.all_courses = data;
  },
  [SET_COURSES](state, data) {
    saveCourses(data);
    state.courses = data;
  },
  [SET_STEPS](state, data) {
    state.steps = data;
  },
  [SET_STUDENTS](state, data) {
    saveStudents(data);
    state.students = data;
  },
  [SET_STUDENT_COURSES](state, data) {
    saveStudentCourses(data);
    state.student_courses = data;
  },
  [SET_ANSWERS](state, data) {
    saveAnswers(data);
    state.answers_full = data;
  },
};

const actions = {
  ...teacherActions,
  ...studentActions,
  ...overallActions,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
