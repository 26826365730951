<template>
  <v-layout column>
    <v-list dense>
      <v-list-item two-line>
        <v-list-item-avatar color="success">
          {{ avatarText() }}
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title id="fullname-text">{{ fullName() }}</v-list-item-title>
          <v-list-item-subtitle id="roles-wrap">{{ userRole() }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-5"></v-divider>

      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(section, i) in sections"
          :key="i"
          :to="section.link"
          replace
        >
          <v-list-item-icon>
            <v-icon v-text="section.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="section.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-divider class="mb-5"></v-divider>
    </v-list>
  </v-layout>
</template>

<script>
/* eslint-disable camelcase */
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'leftMenu',
  computed: {
    ...mapState({
      account: (state) => state.auth.account,
    }),
    ...mapGetters({
      isTeacher: 'isTeacher',
      isStudent: 'isStudent',
    }),
  },
  mounted() {
    if (this.isTeacher) {
      this.sections = this.overall_sections.concat(this.teacher_section);
    } else if (this.isStudent) {
      this.sections = this.overall_sections.concat(this.student_section);
    } else {
      this.sections = this.overall_sections;
    }
  },
  data: () => ({
    selectedItem: 1,
    sections: [],
    overall_sections: [
      { text: 'Главная', icon: 'mdi-home', link: '/' },
      { text: 'Профиль', icon: 'mdi-account', link: '/profile' },
      { text: 'Все курсы', icon: 'mdi-format-list-bulleted-square', link: '/all-courses' },
    ],
    teacher_section: [
      { text: 'Мои ученики', icon: 'mdi-account-group', link: '/t-my-students' },
      { text: 'Мои курсы', icon: 'mdi-label-multiple', link: '/t-my-courses' },
    ],
    student_section: [
      { text: 'Мои подписки', icon: 'mdi-label-multiple', link: '/s-my-courses' },
    ],
  }),
  methods: {
    userRole() {
      if (this.account.person) return this.account.person;
      return '';
    },
    avatarText() {
      let text = '';
      const { username, first_name, last_name } = this.account.user;
      if (first_name) {
        text = first_name.charAt(0);
        if (last_name) {
          text += last_name.charAt(0);
        }
      } else {
        text = username.charAt(0);
      }
      return text.toUpperCase();
    },
    fullName() {
      // eslint-disable-next-line no-unused-vars
      let name;
      const { email, first_name, last_name } = this.account.user;
      if (first_name) {
        name = first_name;
        if (last_name) {
          name += ` ${last_name}`;
        }
      } else {
        name = email;
      }
      return name;
    },
  },
};
</script>

<style scoped>
  #fullname-text{
    font-size: .96em;
    font-weight: 500;
    width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  #roles-wrap {
    font-size: 0.79em;
  }
  #exit {
    margin-top: auto;
  }
</style>
