<template>
  <v-container>
    <v-row class="">
      <v-col cols="10" offset-md="1">
        <h2>Курс {{course.course_name}}</h2>
        <v-card outlined>
          <div class="main-info-wrap">
            <div id="avatar-wrap" class="pt-5">
            <v-list-item-avatar
              tile
              size="150"
            >
              <img src="@/assets/logo.png" alt="">
            </v-list-item-avatar>
            </div>
            <div class="info-wrap">
              <div class="profile-line">
                Название: {{course.course_name}}
              </div>
              <div class="profile-line">
                Описание: {{ course.course_description }}
              </div>
            </div>
            <div class="info-wrap">
              <div class="date-line">
                Создан: {{getDate}}
              </div>
            </div>
          </div>
          <v-divider class="mt-3"></v-divider>
          <v-list class="pa-0">
            <v-list-item>
              <v-file-input
                placeholder="Выберите файл для загрузки"
                prepend-icon="mdi-paperclip"
                show-size
                dense
                chips
                hide-details
                v-model="file"
              >
              </v-file-input>
            </v-list-item>
            <v-list-item link @click="addStepToCourse">
              <v-list-item-icon><v-icon>mdi-step-forward</v-icon></v-list-item-icon>
              <v-list-item-content>Добавить шаг курса</v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="deleteCourse" class="red lighten-4">
              <v-list-item-icon><v-icon>mdi-delete-forever</v-icon></v-list-item-icon>
              <v-list-item-content>Удалить курс</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <div v-if="courses.length">
          <StepCard
            :step_data="step"
            :key="step.id"
            v-for="step of steps"
          />
        </div>
        <p v-else class='no-items'>Вы еще не выложили заданий к этому курсу.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable camelcase */
import {
  DELETE_COURSE,
  GET_COURSES,
  ADD_STEP_TO_COURSE,
  GET_STEPS,
} from '@/store/actions.type';
import { mapState } from 'vuex';
import StepCard from '@/components/StepCard.vue';

export default {
  name: 'TeacherCourse',
  components: {
    StepCard,
  },
  data: () => ({
    file: null,
  }),
  mounted() {
    this.$store.dispatch(GET_STEPS, this.getIdToGetSteps());
  },
  methods: {
    getIdToGetSteps() {
      const courseIdFormData = new FormData();
      courseIdFormData.append('id', this.course.id);
      return courseIdFormData;
    },
    deleteCourse() {
      this.$store.dispatch(DELETE_COURSE, this.delete_course_serializer)
        .then(() => {
          this.$store.dispatch(GET_COURSES);
          this.$router.push({ name: 'TeacherMyCourses' });
        });
    },
    addStepToCourse() {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('id', this.course.id);
      this.file = null;
      this.$store.dispatch(ADD_STEP_TO_COURSE, formData)
        .then(() => {
          this.$store.dispatch(GET_STEPS, this.getIdToGetSteps());
        });
    },
  },
  computed: {
    getDate() {
      const date = new Date(this.course.date);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    getTime() {
      const date = new Date(this.course.date);
      return `${date.getHours()}.${date.getMinutes()}.${date.getSeconds()}`;
    },
    delete_course_serializer() {
      return {
        course_name: this.course.course_name,
        id: this.course.id,
        course_description: this.course.course_description,
        date: this.course.date,
      };
    },
    ...mapState({
      courses: (state) => state.courses.courses,
      steps: (state) => state.courses.steps,
    }),
    course() {
      return this.$store.getters.getCourseById(parseInt(this.$route.params.id, 10));
    },
  },
};
</script>

<style scoped>
  #avatar-wrap {
    display: flex;
    margin-left: 25px;
    align-items: center;
    display: inline-block;
  }
  .main-info-wrap {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
  }
  .info-wrap {
    display: inline-block;
    margin-top: 15px;
  }
  .profile-line {
    margin-top: 5px;
    margin-left: 30px;
    font-size: 18px;
  }
  .date-line {
    margin-left: 30px;
    font-size: 14px;
  }
  h2 {
    margin-bottom: 15px;
  }
  .no-items {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
  }
</style>
